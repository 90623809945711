<template>
  <div>
    <v-dialog
        v-model="dialog"
        persistent
        scrollable
        width="700"
    >
      <form @submit.prevent="save">
        <v-card :disabled="isLoading" :loading="isLoading">

          <v-card-title>
            {{ title }}
            <v-spacer/>
            <v-icon @click="close">mdi-close</v-icon>
          </v-card-title>

          <v-divider/>

          <v-card-text class="pa-8">

            <v-row>
              <v-col>
                <v-text-field v-model="form.name"
                              :error-messages="errors.name"
                              label="Nom complet *"/>
              </v-col>
              <v-col>
                <v-text-field v-model="form.phone"
                              :error-messages="errors.phone"
                              label="Téléphone *"
                              persistent-hint/>
              </v-col>
            </v-row>

            <v-text-field v-model="form.email"
                          :error-messages="errors.email"
                          label="Email *"/>

            <v-text-field v-model="form.username"
                          :error-messages="errors.username"
                          label="Nom d'utilisateur *"/>

            <v-text-field v-model="form.password"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :error-messages="errors.password"
                          :type="show ? 'text':'password'"
                          label="Mot de pass *"
                          @click:append="show = !show"/>

            <v-autocomplete v-if="can('admin')"
                            v-model="form.tenant_id"
                            :error-messages="errors.tenant_id"
                            :items="suppliers"
                            item-text="name"
                            item-value="id"
                            label="Fournisseur *"
            />


            <v-card class="grey lighten-4 mt-5 rounded" flat tile>
              <v-card-title :style="{fontSize : '16px'}" class="gifty white--text">
                <v-icon dark left>mdi-format-list-checks</v-icon>
                Affectation POS
              </v-card-title>

              <v-divider/>

              <v-card-text>
                <v-autocomplete :items="wilayas"
                                item-text="name"
                                item-value="id"
                                label="Filtrer par wilaya"
                                prepend-inner-icon="mdi-filter-outline"/>

                <v-autocomplete v-model="communeIds"
                                :items="communes"
                                chips
                                class="custom-chip"
                                deletable-chips
                                item-text="name"
                                item-value="id"
                                label="Filtrer par commune"
                                multiple
                                prepend-inner-icon="mdi-filter-outline"
                                small-chips/>

                <v-autocomplete v-model="form.salepointIds"
                                :error-messages="errors.salepointIds"
                                :items="setSalepoints"
                                chips
                                class="custom-chip"
                                deletable-chips
                                item-text="pos_name"
                                item-value="id"
                                label="Affecter des POS *"
                                multiple
                                prepend-inner-icon="mdi-home-group"
                                small-chips/>
              </v-card-text>
            </v-card>

            <v-card class="grey lighten-4 mt-5 rounded" flat tile>
              <v-card-title :style="{fontSize : '16px'}" class="gifty white--text">
                <v-icon dark left>mdi-format-list-checks</v-icon>
                Affectation Services
              </v-card-title>

              <v-divider/>

              <v-card-text>
                <v-autocomplete v-model="form.serviceIds"
                                :error-messages="errors.serviceIds"
                                :items="services"
                                chips
                                class="custom-chip"
                                deletable-chips
                                item-text="name"
                                item-value="id"
                                label="Affecter des services *"
                                multiple
                                prepend-inner-icon="mdi-select-group"
                                small-chips/>

              </v-card-text>
            </v-card>

          </v-card-text>

          <v-divider/>

          <v-card-actions>
            <v-spacer/>
            <v-btn :disabled="isLoading"
                   :loading="btnLoading"
                   color="primary"
                   depressed
                   type="submit">
              <v-icon left>mdi-content-save</v-icon>
              Sauvgarder
            </v-btn>
          </v-card-actions>

        </v-card>
      </form>

    </v-dialog>

  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
  data() {
    return {
      title: null,
      dialog: false,
      isLoading: false,
      btnLoading: false,
      show: false,
      wilayas: [],
      communes: [],
      salepoints: [],
      suppliers: [],
      services: [],
      communeIds: [],

      errors: {},
      form: {
        id: null,
        name: null,
        phone: null,
        email: null,
        username: null,
        password: null,
        tenant_id: null,
        salepointIds: [],
        serviceIds: [],
      },
    }
  },
  methods: {
    open(item) {
      if (item) {
        this.title = 'Modifier un recouvreur'
        this.form = {
          id: item.id,
          name: item.name,
          phone: item.phone,
          email: item.email,
          username: item.username,
          tenant_id: item.tenant_id,
          salepointIds: item.salepoints.map(el => el.id),
          serviceIds: item.recuperator_services.map(el => el.id),
        }
      } else {
        this.title = 'Ajouter un recouvreur'
        this.form.id = null
      }

      this.dialog = true
      if (!this.wilayas.length) {
        this.getBase()
      }
    },
    close() {
      this.dialog = false
    },
    getBase() {
      this.isLoading = true
      HTTP.get('/v1/recoveries/base').then(res => {
        this.isLoading = false
        this.wilayas = res.data.data.wilayas
        this.communes = res.data.data.communes
        this.salepoints = res.data.data.salepoints
        this.suppliers = res.data.data.suppliers
        this.services = res.data.data.services
      }).catch(err => {
        this.isLoading = false
        console.log(err)
      })
    },
    save() {
      this.errors = {}
      this.isLoading = true
      this.btnLoading = true

      let methode = this.form.id ? 'update' : 'store'

      HTTP.post('/v1/recoveries/recuperators/' + methode, this.form).then(() => {
        this.isLoading = false
        this.btnLoading = false
        this.$successMessage = 'Cet élément a été enregistré avec succès.'
        this.close()
        this.$emit('refresh')
      }).catch(err => {
        this.errors = err.response.data.errors
        this.$errorMessage = "Une erreur s'est produite veuillez corriger."
        this.isLoading = false
        this.btnLoading = false
        console.log(err)
      })
    },
  },
  computed: {
    setSalepoints: {
      get() {
        return this.salepoints.filter(el => {
          if (this.communeIds.length) {
            return this.communeIds.includes(el.commune_id)
          }
          return el
        });
      },
    },
  },
}
</script>

<style>
.custom-chip .v-chip:not(.v-chip--active) {
  background: #d60c56 !important;
  color: white;
}

.custom-chip .v-chip:not(.v-chip--active) .mdi-close-circle {
  color: white;
}
</style>