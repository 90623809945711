<template>
    <div>
        <v-dialog
                v-model="dialog"
                scrollable
                width="300"
        >
            <v-card>

                <v-card-title>
                    {{ title }}
                    <v-spacer/>
                    <v-icon @click="close">mdi-close</v-icon>
                </v-card-title>

                <v-divider/>

                <v-card-text class="pa-4">
                    <v-list>
                        <v-list-item link v-for="(item, i) in items" :key="i">
                            <v-list-item-content>
                                <v-list-item-title>
                                    <span v-if="item.name">
                                        {{ item.name }}
                                    </span>
                                    <span v-if="item.pos_name">
                                        {{ item.pos_name }}
                                    </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>

            </v-card>

        </v-dialog>
    </div>
</template>

<script>

export default {
    data() {
        return {
            items: [],
            title: null,
            dialog: false
        }
    },
    methods: {
        open(items, title) {
            this.dialog = true
            this.title = title
            this.items = items
        },
        close() {
            this.dialog = false
        },
    }
}
</script>

<style>
</style>